input{
  border-radius: 5px;
  border-color: grey;
  width: 25vw;
}
.centerScreen{
  height: 100vh;
}

.gameCodeDiv{
  margin: 0 0 5% 0;
  padding: 2%;
  border-radius: 10px;
  width: 40vw;
}

.makeCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardContainer{
  margin: 15% 0 0 0;
}

.card{
  width: 10vw;
  height: 25vh;
  color: #ff5e5e;
  background-color: #f2e3e3;
  margin: 1rem;
  font-size: 4rem;
  border-radius: 3px;
}

.selectedCard{
  background-color: #f48484;
}

.optionContainer{
  width: 50vw;
}

.gameOption{
  margin: 5%;
  width: 25vw;
}

.selectedCardPlayerName{
  font-size: 1.5rem;
  font-weight: 300;
}

.winnerName {
  font-weight: 700;
}